import { useCommonApi } from "@/shared/contexts/common-api";
import { getLinksFromNavData, NavLink } from "@/shared/lib";
import { Header } from "@/shared/ui";

export const MainLayoutHeader = () => {
  const commonApiContext = useCommonApi();

  const headerNavigationItems =
    commonApiContext?.layoutSettings?.attributes?.headerNavigation || [];

  const links: NavLink[] = getLinksFromNavData(headerNavigationItems);

  return <Header navLinks={links} />;
};
