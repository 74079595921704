import "@/styles/globals.css";

import { Box, ThemeProvider } from "@mui/material";
import set from "lodash.set";
import { NextPage } from "next";
import App, { AppContext, AppProps } from "next/app";
import { useRouter } from "next/router";
import { NextIntlProvider } from "next-intl";
import { ReactElement, ReactNode, useEffect, useState } from "react";

import { OneLinkSmartScript } from "@/features";
import { CommonApi } from "@/shared/api/common-api";
import {
  LANDING_LOCALE_STORAGE_KEY,
  LANDING_URL_STORAGE_KEY,
} from "@/shared/constants";
import { CommonApiContext } from "@/shared/contexts/common-api";
import { GoogleTagManager, mont } from "@/shared/lib";
import { ICommonApiData, Locale } from "@/shared/types";
import { RouteChangeProgress } from "@/shared/ui";
import { core } from "@/themes";
import { getConfig, getGeneratedOneLinkUrl } from "@/utils";

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  commonData: ICommonApiData;
};

const BNPLApp = (props: AppPropsWithLayout) => {
  const { Component, pageProps, commonData } = props;

  const [currentCommonData, setCurrentCommonData] =
    useState<ICommonApiData | null>(null);

  // fixme `unexpected default value`
  const { locale = "en" } = useRouter();

  const googlePlayLink =
    commonData.layoutSettings?.attributes.globalLinks?.googlePlayLink;
  const appStoreLink =
    commonData.layoutSettings?.attributes.globalLinks?.appStoreLink;

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const localesConvertMap: Record<Locale, string> = {
      [Locale.siLK]: "si",
      [Locale.msMY]: "ms",
      [Locale.viVN]: "vi",
      [Locale.idID]: "id",
      [Locale.en]: "en",
    };

    const landingLocale =
      locale in localesConvertMap
        ? localesConvertMap[locale as Locale]
        : localesConvertMap.en;

    localStorage.setItem(LANDING_LOCALE_STORAGE_KEY, landingLocale);
  }, [locale]);

  useEffect(() => {
    GoogleTagManager.init({
      id: commonData?.config.googleTagManagerId,
    });

    localStorage.setItem(LANDING_URL_STORAGE_KEY, window.location.href);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const actualGooglePlayLink = getGeneratedOneLinkUrl(googlePlayLink);
      const actualAppStoreLink: string = getGeneratedOneLinkUrl(appStoreLink);

      const updatedCommonData = set(
        commonData,
        "layoutSettings.attributes.globalLinks",
        {
          googlePlayLink:
            googlePlayLink !== actualGooglePlayLink
              ? actualGooglePlayLink
              : undefined,
          appStoreLink:
            appStoreLink !== actualAppStoreLink
              ? actualAppStoreLink
              : undefined,
        }
      );

      setCurrentCommonData(updatedCommonData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <NextIntlProvider locale={locale} messages={pageProps.messages}>
      <ThemeProvider theme={core}>
        <CommonApiContext.Provider value={currentCommonData ?? commonData}>
          <Box className={mont.className} sx={{ height: "100%" }}>
            <RouteChangeProgress />

            {getLayout(<Component {...pageProps} />)}

            <OneLinkSmartScript />
          </Box>
        </CommonApiContext.Provider>
      </ThemeProvider>
    </NextIntlProvider>
  );
};

export default BNPLApp;

BNPLApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);
  const locale = context.router.locale || "en";

  const commonData: ICommonApiData = {
    config: getConfig(),
    layoutSettings: null,
  };

  if (process.env.CMS_URL) {
    const commonApi = new CommonApi(locale);

    const layoutSettings = await commonApi.getLayoutSettings();

    commonData.layoutSettings = layoutSettings;
  }

  return {
    ...ctx,
    commonData,
  };
};
