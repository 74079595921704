import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Image from "next/image";
import { FC } from "react";

import background from "@/assets/images/download-app-bg.png";
import { useCommonApi } from "@/shared/contexts/common-api";
import { TDownloadApp } from "@/shared/types";
import { DownloadAppButton } from "@/shared/ui";

type DownloadAppBannerProps = Pick<
  TDownloadApp,
  "image" | "text_bottom" | "text_top"
>;

export const DownloadAppBanner: FC<DownloadAppBannerProps> = (props) => {
  const commonApiContext = useCommonApi();

  const googlePlayLink =
    commonApiContext?.layoutSettings?.attributes.globalLinks?.googlePlayLink;
  const appStoreLink =
    commonApiContext?.layoutSettings?.attributes.globalLinks?.appStoreLink;

  return (
    <Container
      sx={{
        mt: {
          xs: 11.125,
          md: 6.25,
          lg: 12.5,
        },
        mb: {
          xs: 11.25,
          md: 13.875,
          lg: 14.625,
        },
      }}
    >
      <Card>
        <CardMedia
          sx={{
            background: {
              md: `url(${background.src}) no-repeat center center`,
            },
            backgroundSize: {
              md: "cover",
            },
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <Stack
                px={{
                  xs: 3.5,
                  md: 4,
                  lg: 9,
                }}
                py={{
                  xs: 5,
                  md: 4,
                  lg: 8.125,
                }}
                justifyContent="space-between"
                height="100%"
                alignItems={{
                  xs: "center",
                  md: "normal",
                }}
              >
                <Box
                  mb={{
                    xs: 4,
                    md: 0,
                  }}
                >
                  <Typography
                    textAlign={{
                      xs: "center",
                      md: "left",
                    }}
                    variant="text6"
                    color="neutral.70"
                    marginBottom={4.125}
                    maxWidth={{
                      md: 280,
                    }}
                  >
                    {props.text_top}
                  </Typography>
                  <Typography
                    textAlign={{
                      xs: "center",
                      md: "left",
                    }}
                    variant="headline5"
                    sx={{
                      fontSize: {
                        xs: 26,
                        lg: 40,
                      },
                    }}
                  >
                    {props.text_bottom}
                  </Typography>
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  flexDirection={{
                    xs: "column",
                    lg: "row",
                  }}
                  maxWidth={{
                    xs: 260,
                    md: "100%",
                  }}
                  spacing={{
                    xs: 1.5,
                    lg: 2.25,
                  }}
                  useFlexGap={true}
                >
                  {appStoreLink ? (
                    <DownloadAppButton brand="AppStore" href={appStoreLink} />
                  ) : null}

                  {googlePlayLink ? (
                    <DownloadAppButton
                      brand="GooglePlay"
                      href={googlePlayLink}
                    />
                  ) : null}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                pt={{
                  xs: 2,
                  md: 4.5,
                }}
                pr={{
                  xs: 2.25,
                  md: 3.25,
                  lg: 10,
                }}
                display={{
                  xs: "none",
                  md: "flex",
                }}
                height="100%"
                alignItems="end"
              >
                {props.image?.data?.attributes.url ? (
                  <Image
                    src={props.image?.data?.attributes.url}
                    alt={
                      props.image?.data?.attributes.alternativeText || "QR Code"
                    }
                    height={props.image?.data?.attributes.height}
                    width={props.image?.data?.attributes.width}
                    style={{
                      objectFit: "contain",
                      height: "auto",
                    }}
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </CardMedia>
      </Card>
    </Container>
  );
};
