export const getGeneratedOneLinkUrl = (googlePlayLink = ""): string => {
  const mediaSource = {
    keys: ["utm_source"],
    defaultValue: "website_main",
  };
  const campaign = { keys: ["utm_campaign"] };
  const adSet = { keys: ["utm_content"] };
  const ad = { keys: ["utm_term"] };
  const custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" };

  return (
    window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: googlePlayLink,
      afParameters: {
        mediaSource,
        campaign,
        adSet,
        ad,
        afCustom: [custom_ss_ui],
      },
    })?.clickURL ?? googlePlayLink
  );
};
