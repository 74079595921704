import { LinkTarget, NavigationItem } from "@/shared/api/common-api";

export type NavLink = {
  href: string;
  text: string;
  isExternal?: boolean;
  target: LinkTarget;
};

export const navigationsMap: Record<string, Pick<NavLink, "href">> = {
  account: {
    href: "/?sourceFrom=accountHref",
  },
  about: {
    href: "/about",
  },
  "contact-us": {
    href: "/contact-us",
  },
  faq: {
    href: "/faq",
  },
  home: {
    href: "/",
  },
  merchants: {
    href: "/merchants",
  },
  "privacy-policy": {
    href: "/privacy-policy",
  },
  prolongation: {
    href: "/prolongation",
  },
  repayment: {
    href: "/repayment",
  },
  blog: {
    href: "/blog",
  },
  "terms-and-conditions": {
    href: "/terms-and-conditions",
  },
  "rates-terms": {
    href: "/rates-terms",
  },
  "fair-practices-code": {
    href: "/fair-practices-code",
  },
  "experian-terms-and-conditions": {
    href: "/experian-terms-and-conditions",
  },
  "refund-policy": {
    href: "refund-policy",
  },
  grievance: {
    href: "/grievance",
  },
};

export const getLinksFromNavData = (data: NavigationItem[]): NavLink[] => {
  return data.map((item) => {
    let href = "#";
    let target: LinkTarget = "_self";

    if (item.__component === "shared.external-link") {
      href = item.href;
      target = item.target || target;
    }

    if (item.__component === "shared.nav-link") {
      href = navigationsMap[item.to]?.href || href;
    }

    return {
      text: item.text,
      isExternal: item.__component === "shared.external-link",
      href,
      target,
    };
  });
};
