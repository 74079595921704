import { Box, Grid, Stack, Typography } from "@mui/material";
import Image from "next/image";

import { JumbotronOuter } from "@/components/common";
import { useCommonApi } from "@/shared/contexts/common-api";
import { Jumbotron } from "@/shared/types";
import { DownloadAppButton } from "@/shared/ui";

type MainCalculatorProps = Pick<Jumbotron, "content">;
export const JumbotronSection = (props: MainCalculatorProps) => {
  const { content } = props;

  const commonApiContext = useCommonApi();

  const googlePlayLink =
    commonApiContext?.layoutSettings?.attributes.globalLinks?.googlePlayLink;
  const appStoreLink =
    commonApiContext?.layoutSettings?.attributes.globalLinks?.appStoreLink;

  return (
    <JumbotronOuter>
      <Grid
        container
        spacing={2}
        justifyContent={{
          xs: "center",
          lg: "space-between",
        }}
        textAlign={{
          xs: "center",
          lg: "left",
        }}
      >
        <Grid item xs={12} md={7} lg={5} xl={4}>
          <Stack
            justifyContent="space-between"
            height="100%"
            mt={{
              xs: 1,
              md: 0,
            }}
          >
            <Box
              marginBottom={{
                xs: 2,
                md: 2,
                lg: 29,
              }}
            >
              {content?.text1 ? (
                <Typography
                  variant="headline2"
                  color="neutral.0"
                  mb={3.25}
                  fontSize={{
                    xs: 55,
                    md: 66,
                  }}
                  fontWeight={700}
                >
                  {content.text1}
                </Typography>
              ) : null}
              {content?.text2 ? (
                <Typography
                  variant="body2"
                  color="neutral.40"
                  marginBottom={4.25}
                >
                  {content.text2}
                </Typography>
              ) : null}
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              flexDirection={{
                xs: "column",
                lg: "row",
              }}
              spacing={{
                xs: 1.5,
                lg: 2.25,
              }}
              marginBottom={{
                xs: 7,
                md: 1,
              }}
              useFlexGap={true}
            >
              <DownloadAppButton brand="AppStore" href={appStoreLink} />
              <DownloadAppButton brand="GooglePlay" href={googlePlayLink} />
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          lg={7}
          mb={{
            xs: 3,
            md: 0,
          }}
        >
          {content?.image?.data ? (
            <Image
              src={content.image.data?.attributes.url}
              alt={content.image.data?.attributes.alternativeText || ""}
              width={content.image.data?.attributes.width}
              height={content.image.data?.attributes.height}
              style={{
                height: "auto",
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </JumbotronOuter>
  );
};
